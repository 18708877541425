<template>
  <div>
    <aRow>
      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24"> RECEBIMENTO </aCol>
        </aRow>
        <aRow>
          <aCol
            v-for="({ name, value, supplier }, i) in allPayments"
            :key="i"
            :span="24"
          >
            <aRow class="row">
              <aCol :span="10">{{ name }}</aCol>

              <aCol class="supplier" :span="6">
                <div class="dotted-phrase">
                  {{ supplier }}
                </div>
              </aCol>
              <aCol :span="8" class="a-right">
                {{ value | formatPricePtBrComma }}
              </aCol>
            </aRow>
          </aCol>
        </aRow>

        <aRow class="row no-border">
          <aCol class="a-right" :span="24">
            <b>{{ contract.value | formatPricePtBr }}</b></aCol
          >
        </aRow>
      </aCol>

      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24"> COMISSÃO </aCol>
        </aRow>

        <aRow class="row no-border">
          <aCol :span="24"> --- </aCol>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "PrintSaleValuesColumnTwoSection",
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      allPayments: [],
    };
  },
  mounted() {
    JSON.parse(this.contract.meta.payment_methods).forEach((payment) => {
      if (payment === "credit-card") {
        JSON.parse(this.contract.meta.credit_card_rows).forEach(({ id }) => {
          this.allPayments.push({
            name: `CARTÃO DE CRÉDITO #${id}`,
            value:
              this.contract.meta[
                `payment_methods_credit_card_total_value_${id}`
              ],
            supplier:
              this.contract.meta[
                `payment_methods_credit_card_machine_supplier_${id}`
              ],
          });
        });
      }

      if (payment === "bank-transfer") {
        this.allPayments.push({
          name: `TRANSF. BANCÁRIA`,
          value:
            this.contract.meta[`payment_methods_bank_transfer_total_value`],
          supplier: "",
        });
      }

      if (payment === "billing-ticket") {
        this.allPayments.push({
          name: `BOLETO BANCÁRIO`,
          value:
            this.contract.meta[`payment_methods_billing_ticket_total_value`],
          supplier: "",
        });
      }

      if (payment === "payment-card") {
        this.allPayments.push({
          name: `CARTA DE CRÉDITO`,
          value: this.contract.meta[`payment_methods_payment_card_total_value`],
          supplier: "",
        });
      }

      if (payment === "custom-payment") {
        this.allPayments.push({
          name: `PERSONALIZADO`,
          value:
            this.contract.meta[`payment_methods_custom_payment_total_value`],
          supplier: "",
        });
      }
    });
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.row
  padding: 5px 0
  border-bottom: 1px solid #ddd
  font-size: 12px

.supplier
  font-size: 10px
  font-weight: 500
  color: #434a54
.no-border
  border: 0
.label
  font-size: 10px
  font-weight: 600
  color: #ccc
  margin-bottom: 3px
.title
  color: #434a54
  border-bottom: 1px solid #ddd
  font-size: 16px
  font-weight: 600
  margin-bottom: 10px
</style>
